import { AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILED } from '../../redux/ActionType';
import AuthService from '../../service/AuthService';
import History from '../../helper/History';

export default (auth, password) => {

    let authService = new AuthService();
    let history = History.getInstance();

    return dispatch => {
        authService.login(auth, password).then((response) => {
            if (response.data !== null) { 
                dispatch({type: AUTH_LOGIN_SUCCESS, payload: {
                    data: response.data
                }});
                history.forward('/');
            }
        }).catch((response) => {
            dispatch({type: AUTH_LOGIN_FAILED, payload: {
                code: response.message
            }});
        });
    };
}
