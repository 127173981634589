import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import LoginFormComponent from '../../components/LoginFormComponent';
import MessageComponent from '../../components/MessageComponent';

export default class Login extends Component {

    submit = (auth, password) => {
        this.props.loginAction(auth, password);
    }

    render() {
        return (
            <div className="login">
                <Grid stackable centered>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            {this.props.loginErrorCode === null ? '' : <MessageComponent message={this.props.loginErrorCode} />}
                            <LoginFormComponent onSubmit={this.submit} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

Login.propTypes = {
    loginAction: PropTypes.func,
    loginErrorCode: PropTypes.string
};
