import React from 'react';
import account from '../../images/account.png';

class UserMenu extends React.Component {
    render () {
        return (
            <div className="customer-menu">
                <img
                    src={account}
                    width="50"
                    height="50"
                    className="pr-2"
                    alt=""
                />
            </div>
        )
    }

}

export default UserMenu;