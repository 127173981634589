import AuthService from '../service/AuthService';

let authService = new AuthService();

export default {
    access_token: authService.getAccessToken(),
    authenticated: authService.isAuthenticated(),
    loginErrorCode: null,
    signupErrorCode: null
};
