import React from 'react';
import Page from '../components/Page';

export default () => {

    return (
        <Page
            title="Почетна страна"
            breadcrumbs={[{ name: 'табели', active: true }]}
            className="TablePage"
        >
        </Page>
    );

};