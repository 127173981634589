import React from 'react';
import Page from '../components/Page';
import OrderItemAttributes from '../components/Orders/OrderItemAttributes';
import OrderItemOptions from '../components/Orders/OrderItemOptions';
import { 
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown } from 'reactstrap';
import Api from "../helper/Api";

export default class Orders extends React.Component {

  api = Api.getInstance();

  /**
   * Default State object
   * @param {*} props 
   */
  constructor(props) {
    super(props);
    this.state = {
      order: [],
      dropdownOpen: false,
      value : "Time"
    };
    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
  }

  /**
   * A toggle method for the dropdown
   */
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  
  /**
   * A select metgod for getting the dropdown value
   * @param {*} event 
   */
  select(event) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      value: event.target.innerText
    });
  }

  componentDidMount() {
    // The localhost url is configured as a proxy in package.json
    const entity_id  = this.props.match.params.entity_id
    const url = "/api/order/" + entity_id;

    // Fetching data from Ordertracker API
    this.api.fetch('GET', url)
    .then(response => {
      if(response.code == 200) {
        this.setState({ order: response.response })
      } else {
        alert(response.message)
        window.location = '/orders-pending'
      }
    })
    .catch(() => console.log("Can’t access " + url + " response. Blocked by browser?"));

  }

   /**
   *Method for return back to processing page
   * function backEvent() {*}  
   */
  backEvent() {
    window.location = '/orders-processing'
  }

  /**
   * Method for acepting orders over the Ordertracker API
   * @param {*} entity_id 
   */
  acceptOrder(entity_id) {
    if (window.confirm("Дали сте сигруни?")) {
      const url = "/api/order_update";
      const prep_time = this.state.value;
      const status = "processing";

      if (prep_time != "Time") {
          this.api.fetch('POST', url, {
          data: { entity_id, status, prep_time }
        }).then((response) => {
          alert(response.message)
          window.location = '/orders-pending'
        })
      } else {
        alert("Внесете време предвидено за изработка на нарачката!")
      }
    } else {
      window.location = ''
    }
  }

  /**
   * Method for denying orders over the Ordertracker API
   * @param {*} entity_id 
   */
  denyOrder(entity_id) {

    if (window.confirm('Дали сте сигурни дека сакате да ја одбиете нарачката?')) {
        window.location = '/order/deny/' + entity_id
    }

  }
  // Rendering Company
  renderCompany(order) {
    const address = order.shipping_assignments[0].shipping.address;
    if(address)
      if(address.company)
        return (address.company);
    return null;
  }

  convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
  }

  orderStatusTranslated(status) {
    var statuses = {
      'pending': 'Во очекување',
      'procesing': 'Во изработка',
      'completed': 'Комплетирана'
    }
    return statuses[status]
  }

  render() {

    const { order } = this.state;
    const daysOfWeek = new Array("Недела", "Понеделник", "Вторник", "Среда", "Четврток", "Петок", "Сабота");
    let extensionAttributes = order['extension_attributes']
    let productItems = order['items']
    let orderStatus = order['status']
 
    if (!extensionAttributes) {
      return (
        <Page
          title="Детали за нарачката"
          breadcrumbs={[{ name: 'Order Page', active: true }]}
          className="TablePage"
        >
          <div>Loading...</div>
        </Page>
      )
    }

    let isPending = false;
    if (orderStatus == 'pending') isPending = true;

    let convertedDateAndTime = this.convertTZ(order.created_at.replaceAll("-", "/") + " +0000", "Europe/Berlin");
    let createdAt = new Date(convertedDateAndTime);

    return (
      <Page
        title="Детали за нарачката"
        breadcrumbs={[{ name: 'Order Page', active: true }]}
        className="TablePage"
      >
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <p>Имате можност да ја прифатите или одбиете тековната нарачка, доколку истата ја прифаќате селектирајте го предвиденото време за изработка.</p>
              </CardHeader>
              <CardBody>
              <Table responsive className="order-page-table">
                  <tbody>
                    <tr>
                      <th>Време на креирање</th>
                      <td>
                        <p>{daysOfWeek[createdAt.getDay()]}, {createdAt.getHours()}:{(createdAt.getMinutes()<10?'0':'') + createdAt.getMinutes()}</p>
                        <p>{createdAt.getFullYear()}-{createdAt.getMonth() + 1}-{createdAt.getDate()}</p>
                      </td>
                    </tr>
                    <tr>
                      <th>Време предвидено од купувачот</th>
                      <td>
                        {Object.keys(extensionAttributes).map(function(delivery_date, key) {
                            if (key == 1) {
                              let deliveryFormated = new Date(extensionAttributes[delivery_date]);
                              return <p key={key}>{daysOfWeek[deliveryFormated.getDay()]}, {deliveryFormated.getHours()}:{deliveryFormated.getMinutes()}</p>
                            }
                        })}
                      </td>
                    </tr>
                    <tr>
                      <th>Име на купувачот</th>
                      <td>{order.customer_firstname}</td>
                    </tr>
                    <tr>
                      <th>Презиме на купувачот</th>
                      <td>{order.customer_lastname}</td>
                    </tr>
                    <tr>
                        <th>Компанија во која работи</th>
                        <td>{this.renderCompany(order.extension_attributes)}</td>
                    </tr>
                    <tr>
                      <th>Телефон на купувачот</th>
                      <td>
                        {Object.keys(extensionAttributes).map(function(shipping_assignments) {
                          let shipping = extensionAttributes[shipping_assignments][0];
                          return (
                            Object.keys(shipping).map(function(address){
                              let addressType = shipping[address]
                              return (
                                Object.keys(addressType).map(function(address, key){
                                  return addressType[address].telephone
                                })
                              )
                            })
                          )
                        })}
                      </td>
                    </tr>
                    <tr>
                      <th>Адреса</th>
                      <td>
                        {Object.keys(extensionAttributes).map(function(shipping_assignments) {
                          let shipping = extensionAttributes[shipping_assignments][0];
                          return (
                            Object.keys(shipping).map(function(address){
                              let addressType = shipping[address]
                              return (
                                Object.keys(addressType).map(function(address, key){
                                  return addressType[address].street
                                })
                              )
                            })
                          )
                        })}
                      </td>
                    </tr>
                    <tr>
                      <th>Продукти</th>
                      <td>
                        {productItems.map(function(item, key) {
                          // For configurable products
                          if (item.parent_item) {
                            return (
                              <div></div>
                            )
                          }
                          if (item.product_type == "configurable") {
                            var configurableProduct = true
                          }
                          if (item.product_type == "simple") {
                            if (item.product_option) {
                              var customizableProduct = true
                            } else {
                              var simpleProduct = true
                            }
                          }
                          return (
                            <div className="product-items" key={key}>
                              <ul key = {key}>
                                <li><a>Продукт:</a> {item.name}</li>
                                <li><a>Парчина:</a> {item.qty_ordered}</li>
                                { configurableProduct ? (
                                  <li>
                                    <a>Додатоци:</a><OrderItemAttributes attribute_id={item.product_option.extension_attributes.configurable_item_options[0].option_value} />
                                  </li>
                                ):(<li></li>)}
                                { customizableProduct ? (
                                    <li><a>Опции</a> <OrderItemOptions id={item.product_id} options={item.product_option} /></li>
                                ):(<li></li>)}
                                { simpleProduct ? (
                                    <li></li>
                                ):(<li></li>)}
                                <li><a>Цена од парче:</a> {item.row_total_incl_tax}ден</li>
                              </ul>
                              <br/>
                            </div>
                          )
                        })}
                      </td>
                    </tr>
                    <tr>
                      <th>Купон за попуст</th>
                      <td>
                        { order.coupon_code ? (
                          <p>Да</p>
                        ) : (
                          <p>Не</p>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Цена</th>
                      <td>{order.grand_total} денари</td>
                    </tr>
                    <tr>
                      <th>Начин на достава</th>
                      <td>
                        {Object.keys(extensionAttributes).map(function(shipping_assignments, key) {
                          let shipping = extensionAttributes[shipping_assignments][0];
                          return (
                            Object.keys(shipping).map(function(address, key){
                              let addressType = shipping[address]
                              if (addressType.method == 'freeshipping_freeshipping') {
                                return <p key={key} >Без достава</p>
                              } else if ( addressType.method == 'flatrate_flatrate') {
                                return <p key={key} >Достава со такси</p>
                              }
                            })
                          )
                        })}
                      </td>
                    </tr>
                    <tr>
                      <th>Статус</th>
                      <td>{this.orderStatusTranslated(order.status)}</td>
                    </tr>
                    { isPending ?
                    <tr>
                      <th>Време за подготовка</th>
                      <td className="ordertime-button">
                        <UncontrolledButtonDropdown
                          className="text-capitalize m-1">
                          <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle>{this.state.value}</DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={this.select}>15</DropdownItem>
                              <DropdownItem onClick={this.select}>20</DropdownItem>
                              <DropdownItem onClick={this.select}>30</DropdownItem>
                              <DropdownItem onClick={this.select}>40</DropdownItem>
                              <DropdownItem onClick={this.select}>60</DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                        </UncontrolledButtonDropdown>
                      </td>
                    </tr>
                    : null }
                    { isPending ?
                    <tr>
                      <th></th>
                      <td>
                        <Button color="primary" onClick={() => this.acceptOrder(order.entity_id, this.select)} >Прифати</Button>
                        <Button color="scondary" onClick={() => this.denyOrder(order.entity_id)} >Одбиј</Button>
                      </td>
                    </tr>
                    : null }
                    <tr>
                      <th></th>
                      <td>
                        <Button color="primary" onClick={() => this.backEvent()} >Назад</Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );

  }

}
