import React from 'react';
import { Message, Icon } from 'semantic-ui-react';

export default ( { message, type } ) => {

    return (
        <Message icon error>
            <Icon name='exclamation triangle' />
            <Message.Content>
                {message}
            </Message.Content>
        </Message>
    );
}