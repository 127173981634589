import React from 'react';
import {
    Nav,
    Navbar,
    NavItem,
    NavLink,
  } from 'reactstrap';


  class Footer extends React.Component {

    render() {
        return (
          <Navbar>
          <Nav navbar>
            <NavItem>
              <div className="footer-cr">
                  2019 Order Tracker, source on <a href="https://github.com/ilchebedelovski/ordertracker-frontend" target="_blank" rel="noopener noreferrer">GitHub</a>
              </div>
            </NavItem>
          </Nav>
        </Navbar> 
      );
    }
  }

  export default Footer;