import React from 'react';
import Api from "../../helper/Api";

class OrderItemOptions extends React.Component {

    api = Api.getInstance();

    /**
     * Default State object
     * @param {*} props 
     */
    constructor(props) {
      super(props);
      this.state = {
        options: [],
      };
    }

    componentDidMount() {

        // The localhost url is configured as a proxy in package.json
        const product_id  = this.props.id;
        const url = "/api/catalog/products/product/options/" + product_id;
    
        // Fetching the options for a given product id
        this.api.fetch('GET', url)
        .then(response => {
          if(response.code == 200) {
            this.setState({ options: response.response.items })
          } else {
            alert(response.message)
          }
        })
        .catch(() => console.log("Can’t access " + url + " response. Blocked by browser?"));
    
    }

    render() {

      const { options } = this.state;
      let optionsItems = options;
      let productOptions = this.props.options;
      let productOptionsArray = productOptions['extension_attributes']['custom_options'][0]['option_value'];

      return (
        <div>
          {optionsItems.map(function(item, key) {
            let option = item.options;
            return (
              <div key={key}>
                {option.map(function(o, k1) {
                  let values = o.values;
                  return (
                    <ul key={k1}>
                      {values.map(function(v, k2) {
                        if (productOptionsArray.includes(v.option_type_id)) {
                          return (
                            <li key={k2}>{v.title}</li>
                          )
                        } else {
                          return (
                            <li></li>
                          )
                        }
                      })}  
                    </ul>
                  )
                })}
              </div>
            )
          })}
        </div>
      )
   }
}

export default OrderItemOptions;
