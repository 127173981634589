import React from 'react';
import logo from '../../images/order-tracker-logo.png';

class LogoRight extends React.Component {
    render () {
        return (
            <div className="logo-sidebar">
                <img
                    src={logo}
                    width="50"
                    height="50"
                    className="pr-2"
                    alt=""
                />
            </div>
        )
    }

}

export default LogoRight;