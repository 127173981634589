import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Login from './Login';
import loginAction from '../../action/auth/loginAction';

let mapState = (state) => {
    return {
        loginErrorCode: state.auth.loginErrorCode
    };
}

let mapActions = (dispatch) => {
    return {
        loginAction: bindActionCreators(loginAction, dispatch)
    };
}

export default connect(mapState, mapActions)(Login);
