import React from 'react';
import Api from "../../helper/Api";

class OrderItemAttributes extends React.Component {

    api = Api.getInstance();

    /**
     * Default State object
     * @param {*} props 
     */
    constructor(props) {
      super(props);
      this.state = {
        attribute: "",
      };
    }

    componentDidMount() {

        // The localhost url is configured as a proxy in package.json
        const attribute_id  = this.props.attribute_id;
        const url = "/api/catalog/attribute/" + attribute_id;
    
        // Fetching the options for a given product id
        this.api.fetch('GET', url)
        .then(response => {
          if(response.code == 200) {
            this.setState({ attribute: response.response })
            console.log(response)
          } else {
            alert(response.message)
          }
        })
        .catch(() => console.log("Can’t access " + url + " response. Blocked by browser?"));
    
    }

    render() {

        const { attribute } = this.state;

        return (
            <div>{attribute}</div>
        )

   }
}

export default OrderItemAttributes;