import React from 'react';
import avatar from "../images/avatar.svg"

class Avatar extends React.Component {

    render() {
        return (
            <div className="avatar">
                <img src={avatar} />
            </div>
        )
   }
}

export default Avatar;