import reduxStore from "./reduxStore";
import { CHANGE_ROUTE } from './ActionType';

class ReduxHelper {

    store = reduxStore();

    changeRoute(path) {
        this.store.dispatch({
            type: CHANGE_ROUTE,
            payload: { path }
        });
    }
}

let instance = null;
ReduxHelper.getInstance = () => {
    if (instance === null) {
        instance = new ReduxHelper();
    }
    return instance;
};

export default ReduxHelper;
