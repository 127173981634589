import React from 'react';
import Page from '../components/Page';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import Pagination from 'react-bootstrap/Pagination';
import { createHeart } from 'heartbeats';
import Api from "../helper/Api";

export default class Orders extends React.Component {

  api = Api.getInstance();
  heartbeat = null
  updateFinished = true

  /**
    * Default State object 
    */
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      count: 0,
      start: 1,
      limit: 10
    };
  }

  _updateTable() {
    if (!this.updateFinished) return
    this.updateFinished = false
    /**
      *  The localhost url is configured as a proxy in package.json
      */
    let { start } = this.state;
    let { limit } = this.state;
    const url = "/api/get_orders_by_status/canceled?start=" + start + "&limit=" + limit;

    /**
      *  Fetching data from Ordertracker API
      */
    this.api.fetch('GET', url)
    .then(response => { return response })
    .then(data => {
      this.updateFinished = true
      this.setState({ orders: data.response.results })
      this.setState({ count: data.response.count })
    })
    .catch(() => {
      this.updateFinished = true
      console.log("Can’t access " + url + " response. Blocked by browser?")
    });
  }

  /**
    * Implementing the heartbeat library for refreshing the component
    * and importing all server side changes.
    */
  componentDidMount() {
    this.heartbeat = createHeart(10000)
    this.heartbeat.createEvent(1, () => this._updateTable())
    this._updateTable()
  }

  /**
   * Reseting the component
   */
  componentWillUnmount() {
    this.heartbeat.kill()
    this.heartbeat = null
  }

  /**
   * 
   * @param {*} p
   * A method for changing the page 
   */
  pageChanged(p){
    let { start } = this.state;
    start = p;
    this.setState({
        start,
    });
    this._updateTable();
  }
  // Rendering Company
  renderCompany(order) {
    const address = order.shipping_assignments[0].shipping.address;
    if(address)
      if(address.company)
        return (address.company);
    return null;
  }

  convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
  }

  render() {

    const { orders } = this.state;
    const { count } = this.state;
    const pageItems = [];
    const daysOfWeek = new Array("Недела", "Понеделник", "Вторник", "Среда", "Четврток", "Петок", "Сабота");
    const ulStyle = {
        paddingLeft: 0
    };

    // Rendering pagination
    if (count > 10) {

      let remainder = count % 10;
      let pages = Math.floor(count/10);

      if (remainder != 0) {
        pages = pages + 1
      }
         
      for(let i = 1; i <= pages; i++) {
        pageItems.push(<Pagination.Item onClick={() => this.pageChanged(i)}>{i}</Pagination.Item>)
      }

    } else {
      pageItems.push(<Pagination.Item onClick={() => this.pageChanged(1)}>{1}</Pagination.Item>)
    }
    
    return (
      <Page
        title="Откажани нарачки"
        breadcrumbs={[{ name: 'нарачки-откажани', active: true }]}
        className="TablePage"
      >
        <Row>
          <Col>
            <Card className="mb-3">
              <CardHeader>Откажани нарачки</CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Датум на нарачка</th>
                      <th>Име</th>
                      <th>Компанија</th>
                      <th>Артикал</th>
                      <th>Цена</th>
                      <th>Причина</th>
                      <th>Статус</th>
                    </tr>
                  </thead>
                  <tbody>{orders.map((order, key) => {
                    let convertedDateAndTime = this.convertTZ(order.created_at.replaceAll("-", "/") + " +0000", "Europe/Berlin");
                    let createdAt = new Date(convertedDateAndTime);
                    return (
                      <tr key = {key}>
                        <td>{order.entity_id}</td>
                        <td>
                          <p>{daysOfWeek[createdAt.getDay()]}, {createdAt.getHours()}:{(createdAt.getMinutes()<10?'0':'') + createdAt.getMinutes()}</p>
                          <p>{createdAt.getFullYear()}-{createdAt.getMonth() + 1}-{createdAt.getDate()}</p>
                        </td>
                        <td>{order.customer_firstname}</td>
                        <td>{this.renderCompany(order.extension_attributes)}</td>
                        <td>
                          {Object.keys(order.items).map((item, i) => {
                            // For configurable products exclude the simple product
                            if (order.items[item]['parent_item_id']) {
                              return (
                                <div></div>
                              )
                            } else {
                              return (
                                <ul style={ulStyle} key = {i}>
                                  <li>{order.items[item]['qty_ordered']}п - {order.items[item]['name']}</li>
                                </ul>
                              )
                            }
                          })}
                        </td>
                        <td>{order.grand_total} ден</td>
                        <th>Причина</th>
                        <td>
                          <p>Откажани</p>
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div>
          <Pagination>
            {pageItems}
          </Pagination>
        </div>
      </Page>
    );
  }

}
