import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger'
import authReducer from './authReducer';

let getMiddleware = () => {
    if (process.env.NODE_ENV === 'development') {
        return applyMiddleware(thunk, logger);
    } else {
        return applyMiddleware(thunk);
    }
}

let store = createStore(
    combineReducers({
        auth: authReducer
    }),
    getMiddleware(),
);

export default () => {
    return store;
}
