import React from 'react';
import UserMenu from './UserMenu';
import {
    Button,
    Input,
    Nav,
    Navbar,
    NavItem,
    NavLink,
    Popover,
    PopoverBody,
  } from 'reactstrap';
import bn from '../../utils/bemnames';
import UserCard from '../../container/UserCard'
import {
  MdClearAll
} from 'react-icons/md';

const bem = bn.create('header');

class Header extends React.Component {
    
    state = {
      isOpenUserCardPopover: false
    }
    
    toggleUserCardPopover = () => {
      this.setState({
        isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
      });
    }

    handleSidebarControlButton = event => {
      event.preventDefault();
      event.stopPropagation();
      document.querySelector('.sidebar').classList.toggle('sidebar-open');
      document.querySelector('.content').classList.toggle('content-open');
    };

    render() {
        return (
            <div className="header">
              <Nav navbar className="mr-2 sidebar-clear">
                <Button outline onClick={this.handleSidebarControlButton}>
                  <MdClearAll size={30} />
                </Button>
              </Nav>
              <Input color="primary" placeholder="Пребарувај..." type="search" >
              </Input>
              <Navbar light expand className={bem.b('bg-white')}>
                <Nav navbar className="mr-2">
                  <NavItem className="nav-itm">
                    <NavLink id="Popover2">
                      <UserMenu
                        onClick={this.toggleUserCardPopover}
                        className="can-click"
                      />
                    </NavLink>
                    <Popover
                      placement="bottom-end"
                      isOpen={this.state.isOpenUserCardPopover}
                      toggle={this.toggleUserCardPopover}
                      target="Popover2"
                      className="p-0 border-0"
                      style={{ minWidth: 250 }}
                    >
                      <PopoverBody className="p-0 border-light">
                        <UserCard />
                      </PopoverBody>
                    </Popover>
                  </NavItem>
                </Nav>
              </Navbar>
            </div>
        )
    }

}

  export default Header;