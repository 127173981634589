import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserCard from './UserCard';
import logoutAction from '../../action/auth/logoutAction';

let mapState = (state) => {
    return {
        authenticated : state.auth.authenticated,
        data: state.auth.data
    };
}

let mapActions = (dispatch) => {
    return {
        logoutAction: bindActionCreators(logoutAction, dispatch)
    };
}

export default connect(mapState, mapActions)(UserCard);
