import md5 from 'md5';
import Api from "../helper/Api";
import Session from '../helper/Session';

class AuthService {
    
    authSession = new Session('auth');
    api = Api.getInstance();

    login(username, password) {
        return this.api.fetch('POST', '/api/login', {
            data: { username, password }
        }).then((response) => {
            this.authSession.save('access_token', response.access_token);
            this.authSession.save('authenticated', true);
            this.getUserDetails(username);
            this.api.addToken(response.access_token);
            return response;
        });
    }

    signup(name, email, username, password) {
        return this.api.fetch('POST', '/api/register', {
            data: { name, email, username, password }
        }).then((response) => {
            return response;
        });
    }

    logout() {
        this.authSession.remove('access_token') 
        this.authSession.save('authenticated', false);
    }

    getAccessToken() {
        return this.authSession.get('access_token') 
    }

    getUserDetails(username) {
        return this.api.fetch('GET', `/api/user/${username}`).then(response => {
            this.authSession.save('name', response.name);
            this.authSession.save('email', response.email);
            this.authSession.save('username', response.username);
        });
    }

    isAuthenticated() {
        return this.authSession.get('authenticated') === null ? false : this.authSession.get('authenticated');
    }

    generateHash(data) {
        return md5(JSON.stringify(data));
    }
    
}

export default AuthService;
