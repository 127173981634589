import React, { Component } from "react"
import { Card, CardTitle, CardSubtitle, CardBody } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import {
NavItem,
NavLink as BSNavLink
} from 'reactstrap';
import PropTypes from 'prop-types';

import Session from '../../helper/Session';
import Avatar from "../../components/Avatar.js"

class UserCard extends React.Component {

    authSession = new Session('auth');

    logout = () => {
        this.props.logoutAction();
    }
    
    render() {
        let name = this.authSession.get('name');
        let email = this.authSession.get('email');
        return (
            <div>
                <Card inverse>
                    <CardBody className="d-flex justify-content-center align-items-center flex-column">
                    <Avatar className="avt-1 avt-s" />
                        <CardTitle>{name}</CardTitle>
                        <CardSubtitle>{email}</CardSubtitle>
                    </CardBody>
                    <CardBody className="c-flex j-contente-center f-column">       
                         <NavItem clasName="nav-item b-nav-item">
                             <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M19 2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h4l3 3 3-3h4c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 3.3c1.49 0 2.7 1.21 2.7 2.7 0 1.49-1.21 2.7-2.7 2.7-1.49 0-2.7-1.21-2.7-2.7 0-1.49 1.21-2.7 2.7-2.7zM18 16H6v-.9c0-2 4-3.1 6-3.1s6 1.1 6 3.1v.9z"></path></svg>
                             <NavLink to="/profile">Profile</NavLink>                                                       
                         </NavItem>
                         <NavItem clasName="nav-item b-nav-item">
                             <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm7-7H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-1.75 9c0 .23-.02.46-.05.68l1.48 1.16c.13.11.17.3.08.45l-1.4 2.42c-.09.15-.27.21-.43.15l-1.74-.7c-.36.28-.76.51-1.18.69l-.26 1.85c-.03.17-.18.3-.35.3h-2.8c-.17 0-.32-.13-.35-.29l-.26-1.85c-.43-.18-.82-.41-1.18-.69l-1.74.7c-.16.06-.34 0-.43-.15l-1.4-2.42c-.09-.15-.05-.34.08-.45l1.48-1.16c-.03-.23-.05-.46-.05-.69 0-.23.02-.46.05-.68l-1.48-1.16c-.13-.11-.17-.3-.08-.45l1.4-2.42c.09-.15.27-.21.43-.15l1.74.7c.36-.28.76-.51 1.18-.69l.26-1.85c.03-.17.18-.3.35-.3h2.8c.17 0 .32.13.35.29l.26 1.85c.43.18.82.41 1.18.69l1.74-.7c.16-.06.34 0 .43.15l1.4 2.42c.09.15.05.34-.08.45l-1.48 1.16c.03.23.05.46.05.69z"></path></svg>
                             <NavLink to="/settings">Settings</NavLink>                                                       
                         </NavItem>
                         <NavItem clasName="nav-item b-nav-item">
                             <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path></svg>
                             <NavLink onClick={this.logout} >Log Out</NavLink>                                                       
                         </NavItem>
                    </CardBody>
                </Card>
            </div>
        )      
    }
}

export default UserCard;

UserCard.propTypes = {
    logoutAction: PropTypes.func
};
