import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthService from '../../service/AuthService';
import { Header, Sidebar, Content, Footer} from '../../components/Layout';

class MainLayout extends React.Component {

    render() {
        const { children } = this.props;
        let authService = new AuthService();
        return authService.isAuthenticated() ? (
//        return (
            <main>
                <Sidebar />
                <div className="content">
                    <Content fluid>
                        <Header />
                        {children}
                        <Footer />
                    </Content>
                </div>
            </main>
        ) : (
            <Redirect to='/login' />
        );
    }

}

export default MainLayout;