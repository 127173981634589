import { AUTH_LOGOUT_SUCCESS } from '../../redux/ActionType';
import AuthService from '../../service/AuthService';

export default () => {

    let authService = new AuthService();

    return dispatch => {
        authService.logout();
        dispatch({type: AUTH_LOGOUT_SUCCESS });
    };
}
