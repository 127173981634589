import React from 'react';
import Page from '../components/Page';
import { Button } from 'reactstrap';
import Api from "../helper/Api";

export default class Orders extends React.Component {

  api = Api.getInstance();

  /**
   * Default State object
   * @param {*} props 
   */
  constructor(props) {
    super(props);
    this.state = {
      order: [],
      reason: null
    };
  }

  componentDidMount() {
    // The localhost url is configured as a proxy in package.json
    const entity_id  = this.props.match.params.entity_id
    const url = "/api/order/" + entity_id;

    // Fetching data from Ordertracker API
    this.api.fetch('GET', url)
    .then(response => { return response } )
    .then(data => this.setState({ order: data.response }))
    .catch(() => console.log("Не можам да пристапам " + url + " response. Blocked by browser?"));

  }

  /**
   * Handle the reason from Radio Inputs
   */
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  /**
   * Deny Order method
   */
  denyOrder() {

    const url = "/api/order_update";
    const entity_id  = this.props.match.params.entity_id
    const status = "canceled"

    if (this.state.reason) {
      let reason = this.state.reason;
      this.api.fetch('POST', url, {
        data: { entity_id, status, reason}
      }).then((response) => {
        alert(this.state.reason)
        window.location = '/orders-pending'
      })
    } else {
      alert("Изберете причина поради која ја одбивате нарачката.")
    }

  }

  denyOrderMessage() {

  }

  render() {

    const entity_id  = this.props.match.params.entity_id
    const reason1 = "Нарачката " + entity_id + " е одбиена поради недостиг на залиха. ITC Food 074222497."
    const reason2 = "Нарачката " + entity_id + " за жал е одбиена. ITC Food 074222497."

    return (

      <Page
        title="Deny Order Page"
        breadcrumbs={[{ name: 'Order Page', active: true }]}
        className="TablePage"
      >
        <div>
          <p>Изберете причина поради која ја одбивате нарачката:</p>
          <ul>
            <li>
              <input 
                type="radio"
                name="reason"
                value={reason1}
                onChange={this.handleChange}
              />
              Поради недостиг на залиха
            </li>
            <li>
              <input 
                type="radio"
                name="reason"
                value={reason2}
                onChange={this.handleChange}
              />
              Друга причина
            </li>
          </ul>
          <Button type="submit" onClick={() => this.denyOrder()} >Избери</Button>
        </div>
      </Page>
    );
  }

}