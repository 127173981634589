import React from 'react';
import { Container } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import Login from '../container/Login';
import AuthService from '../service/AuthService';

export default () => {
    
    let authService = new AuthService();

    return !authService.isAuthenticated() ? (
        <Container fluid>
            <Login />
        </Container>
    ) : (
        <Redirect to='/' />
    );
};