import React from 'react';
import { Segment, Input, Button,} from 'semantic-ui-react';
import Logo from './Layout/Logo';

export default ({ onSubmit }) => {
    
    let auth = null;
    let password = null;
    let signupUrl = window.location.origin.toString() + "/signup"

    return (
        <Segment>
            <Logo />
            <Input onChange={(e, data) => auth = data.value} fluid type='text' icon='user' iconPosition='left' placeholder='Корисничко име' />
            <br />
            <Input onChange={(e, data) => password = data.value} fluid type='password' icon='key' iconPosition='left' placeholder='Лозинка' />
            <br />
            <br />
            <Button onClick={() => onSubmit(auth, password)} fluid color='orange'>Најави се</Button>
        </Segment>
    );
};
