class Session {
    
    namespace = null;
    data = {};
    
    constructor(namespace = 'default') {
        this.namespace = namespace;
        this.read();
    }

    get(key) {
        return this.data[key] ? this.data[key] : null;
    }

    save(key, value) {
        this.data[key] = value;
        this.persist();
    }

    remove(key) {
        let can = this.data[key] ? true : false;
        if (can) {
            delete this.data[key];
            this.persist();
            return true;
        } else {
            return false;
        }
    }

    read() {
        let value = sessionStorage.getItem(`session-${this.namespace}`);
        if (value !== null) {
            try {
                this.data = JSON.parse(value);
            } catch (error) {
                sessionStorage.removeItem(`session-${this.namespace}`);
            }
        }
    }

    persist() {
        let data = JSON.stringify(this.data);
        sessionStorage.setItem(`session-${this.namespace}`, data);
    }
}

export default Session;
