import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';
import './style-a.css';
import './style-320.css';
import './style-480.css';
import './style-768.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
