import React, { Component } from 'react';
import { LayoutRoute, MainLayout } from './components/Layout';
import { Switch, Route, Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import reduxStore from './redux/reduxStore';
import History from './helper/History';

import DashboardPage from './pages/DashboardPage';
import LoginPage from './pages/LoginPage';
import OrdersPendingPage from './pages/OrdersPendingPage';
import OrdersProcessingPage from './pages/OrdersProcessingPage';
import OrdersCompletedPage from './pages/OrdersCompletedPage';
import OrdersCanceledPage from './pages/OrdersCanceledPage';
import OrderPage from './pages/OrderPage';
import OrderDenyPage from './pages/OrderDenyPage';
import NotFoundPage from './pages/NotFoundPage';

export default class App extends Component {

  history = History.getInstance();

  render() {
    const store = reduxStore();
    return (
      <Provider store={store}>
        <Router history={this.history.api}>
            <Switch>
                <LayoutRoute
                  exact
                  path="/"
                  layout={MainLayout}
                  component={DashboardPage}
                />
                <Route
                  exact
                  path="/login"
                  component={LoginPage}
                />
                <LayoutRoute
                  exact
                  path="/orders-pending"
                  layout={MainLayout}
                  component={OrdersPendingPage}
                />
                <LayoutRoute
                  exact
                  path="/orders-processing"
                  layout={MainLayout}
                  component={OrdersProcessingPage}
                />
                <LayoutRoute
                  exact
                  path="/orders-completed"
                  layout={MainLayout}
                  component={OrdersCompletedPage}
                />
                <LayoutRoute
                  exact
                  path="/orders-canceled"
                  layout={MainLayout}
                  component={OrdersCanceledPage}
                />
                <LayoutRoute
                  exact
                  path="/order/:entity_id"
                  layout={MainLayout}
                  component={OrderPage}
                />
                <LayoutRoute
                  exact
                  path="/order/deny/:entity_id"
                  layout={MainLayout}
                  component={OrderDenyPage}
                />
                <Route
                  exact
                  component={NotFoundPage}
                />
            </Switch>
        </Router>
      </Provider>
    );
  }
}
