import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './LogoRight';
import {
    Nav,
    NavItem
} from 'reactstrap';
  
  class Sidebar extends React.Component {

    render() {
        return (
            <aside className="sidebar">
                <div className="sidebar-background"></div>
                <div className="sidebar-internal">
                    <Logo />
                    <Nav vertical>
                        <NavItem clasname="nav-item nv-itm">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="cr-sidebar__nav-item-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path></svg>
                            <NavLink to="/">ПОЧЕТНА</NavLink>                            
                        </NavItem>
                        <NavItem clasname="nav-item nv-itm">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="cr-sidebar__nav-item-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42zM18 11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z"></path></svg>
                            <NavLink to="/orders-pending">НАРАЧКИ ВО ОЧЕКУВАЊЕ</NavLink>                                
                        </NavItem>
                        <NavItem clasname="nav-item nv-itm">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="cr-sidebar__nav-item-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 12l-4-4h8l-4 4z"></path></svg>
                            <NavLink to="/orders-processing">НАРАЧКИ ВО ПРОЦЕС</NavLink>                                
                        </NavItem>
                        <NavItem clasname="nav-item nv-itm">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="cr-sidebar__nav-item-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM8 17.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5zM9.5 8c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5S9.5 9.38 9.5 8zm6.5 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path></svg>
                            <NavLink to="/orders-completed">ГОТОВИ НАРАЧКИ</NavLink>                                
                        </NavItem>
                        <NavItem clasname="nav-item nv-itm">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="cr-sidebar__nav-item-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"></path></svg>
                            <NavLink to="/orders-canceled">ОТКАЖАНИ НАРАЧКИ</NavLink>                                
                        </NavItem>
                      </Nav>
                </div>

            </aside>
        )
    }

  }

  export default Sidebar;