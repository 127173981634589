import { CHANGE_ROUTE, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILED,
    AUTH_LOGOUT_SUCCESS, AUTH_SIGNUP_SUCCESS, AUTH_SIGNUP_FAILED } from './ActionType';
import initialAuthState from '../redux/inititalAuthState';

export default (state = initialAuthState, { type, payload }) => {
    state = { ...state };
    
    if (type === CHANGE_ROUTE) {
        state.loginErrorCode = null;
        state.signupErrorCode = null;
    }
    
    if (type === AUTH_LOGIN_SUCCESS) {
        state.data = payload.data;
        state.authenticated = true;
    }

    if (type === AUTH_LOGIN_FAILED) {
        state.loginErrorCode = payload.code;
    }

    if (type === AUTH_LOGOUT_SUCCESS) {
        state.data = null;
        state.authenticated = false;
    }

    if (type === AUTH_SIGNUP_SUCCESS) {
        // nothing to do
    }

    if (type === AUTH_SIGNUP_FAILED) {
        state.signupErrorCode = payload.code;
    }

    return state;
}
