import React from 'react';

class Container extends React.Component {

  render() {
    return (
      <div className="main">Container</div>
    )
  }
  
}

export default Container;
